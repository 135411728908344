.reports-wrapper {

  .reports-bar {
    margin: 10px 10px 60px 10px;
    display: flex;
    gap: 20px;

    .reports-bar-item {
      //max-width: 33%;
    }
  }
}

.topbar-menu {
  margin: 0 20px;
  display: flex;
  gap: 20px;
}
