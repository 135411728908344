.grid-wrapper {
  width: calc(100% - 40px);
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.grid-title {
  margin-bottom: 0;
}

.title {
  margin: 0 0 15px 0;
}

.payment-form {
  height: 100%;
  padding: 10px 20px;
}

.form-container {
  overflow: hidden;
  height: calc(100% - 120px);
  margin-top: 15px;

  #form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;
  }
}

.button-container {
  display: flex;
  gap: 10px;
}

.buttons-bottom {
  display: flex;

  &.padded {
    margin: 5px 0;
  }
}

.dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
  padding-top: 12px;
}

.form-toolbar, .form-settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}