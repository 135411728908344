@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .site-name {
    font-size: 20px;
    padding: 0 40px;
  }
}